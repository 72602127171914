/**
 * Returns error message for phone number input field if invalid
 * @param ID Practice ID to validate
 * @returns Undefined if valid or empty, error message if invalid
 */
const isValidPracIDInput = (ID: string): string | undefined => {
  return isPracID(ID) || ID === "" ? undefined : "Practice ID is invalid";
};

/**
 * Validates a practice ID
 * @param ID Practice ID to validate
 * @returns true if practice ID is formatted xxx-xxxxxx
 */
export function isPracID(ID: string): boolean {
  return /^[0-9]{3}-[0-9]{6}$/.test(ID);
}

export default isValidPracIDInput;
