import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import isWeb from "../../../../models/isWeb";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { defaultConsultStatuses } from "../Toolbar/ConsultStatus";
import SearchIcon from "@material-ui/icons/Search";
import Collapse from "@material-ui/core/Collapse";
import usePermissions from "../../../../components/usePermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";

interface SearchBarProps {
  enableSearch: boolean;
  runSearch: (
    params: string[],
    status: string,
    initialSearch?: boolean,
  ) => void;
  clearSearch: () => void;
  maxSearchTerms?: number;
  placeholderText: string;
}

const ConsultSearchBar = ({
  enableSearch,
  runSearch,
  clearSearch,
  maxSearchTerms = 10,
  placeholderText,
}: SearchBarProps) => {
  const [searchParams, setSearchParams] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);

  const handleSearch = () => {
    runSearch(searchParams, status, true);
  };

  const handleClearSearch = () => {
    setSearchParams([]);
    setStatus("");
  };

  useEffect(() => {
    if (status === "" && searchParams.length === 0 && enableSearch) {
      clearSearch();
    }
  }, [searchParams, status]);

  const { granted: hasEditStatusPermissions } = usePermissions({
    resourceKey: resourceKeys.editActivityStatus,
  });

  const getHelperText = (inputValue: string, hasFocus: boolean) => {
    //If the textfield value could exist in the list, don't show helper text
    //If not focused, don't show helper text
    if (
      !hasFocus ||
      inputValue.length === 0 ||
      searchParams.some((t) =>
        t.toLowerCase().includes(inputValue.toLowerCase()),
      )
    )
      return undefined;

    //If no results showing, show helper text to add a new tag
    return "Press enter to add multiple search terms";
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShow(!show)}
        >
          {show ? "Hide" : "Show"} Search
        </Button>
      </Grid>
      {enableSearch && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearSearch}
          >
            Clear
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Collapse in={show}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={12} md={true}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={searchParams}
                filterSelectedOptions
                autoSelect
                limitTags={maxSearchTerms}
                onChange={(e, value) => {
                  setSearchParams(
                    (value as string[]).map((str) => {
                      try {
                        return str.trim();
                      } catch (e) {
                        return str;
                      }
                    }),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholderText}
                    variant="outlined"
                    margin="dense"
                    helperText={
                      isWeb() &&
                      getHelperText(
                        (params?.inputProps as any)?.value,
                        document.activeElement ===
                          (params?.inputProps as any)?.ref?.current,
                      )
                    }
                    inputProps={{
                      ...params.inputProps,
                      enterkeyhint: "done", //Allow enter to add tag on mobile
                    }}
                  />
                )}
              />
            </Grid>
            {hasEditStatusPermissions && (
              <Grid item xs={12} sm={10} md={4}>
                <TextField
                  label="Status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value as string);
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  select
                  InputProps={{
                    endAdornment:
                      status !== "" ? (
                        <IconButton
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          onClick={() => setStatus("")}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : null,
                  }}
                  // Fixes bug where popup continues to move up every time you select an option
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  {defaultConsultStatuses.order.map((status, i) => (
                    <MenuItem
                      key={`search_status_${status}_${i}`}
                      value={status}
                    >
                      <ListItemText
                        primary={defaultConsultStatuses.statuses[status]?.label}
                      />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default ConsultSearchBar;
