import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import isValidPracIDInput from "./isPracID";
import sharedStyles from "../../shared/sharedStyles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const shared = sharedStyles(theme);
  return {
    ...shared,
  };
});

//Formats input as prac-id with format 123-456789
const PracIDInput = ({
  name,
  control,
  defaultValue,
  rules,
  disabled,
  label,
  errorTextPadding,
  ...rest
}: ControlledInputProps) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={Object.assign(rules ? rules : {}, {
        validate: {
          isPracID: isValidPracIDInput,
        },
      })}
      render={({ field, fieldState }) => (
        <NumberFormat
          {...field}
          {...rest}
          format="###-######"
          mask="_"
          customInput={TextField}
          label={label}
          disabled={disabled}
          className={classes.canDisable}
          error={fieldState.error !== undefined}
          helperText={
            errorTextPadding
              ? fieldState.error?.message
                ? fieldState.error.message
                : " "
              : fieldState.error?.message
          }
          variant="outlined"
          fullWidth
          margin="dense"
          autoComplete="off"
          InputLabelProps={{
            disableAnimation: true,
          }}
          inputProps={{
            "aria-autocomplete": "none",
            list: "autocompleteOff",
          }}
        />
      )}
    />
  );
};

export default PracIDInput;
